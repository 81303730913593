img {
	border: none;
	-ms-interpolation-mode: bicubic;
	max-width: 100%
}



.tickettable {
	border-collapse: separate;
	mso-table-lspace: 0;
	mso-table-rspace: 0;
	width: 100%
}

.tickettable td {
	font-family: sans-serif;
	font-size: 14px;
	vertical-align: top
}




.bg-primary {
    background-color: #007bff !important
}

.bg-gray,
.bg-primary {
    -webkit-print-color-adjust: exact
}

.bg-gray {
    background-color: #e2e2e2 !important
}

.bg-dark {
    background-color: #666 !important;
    -webkit-print-color-adjust: exact
}
.p-05 {
    padding: 5px
}

.p-1 {
    padding: 10px
}

.p-2 {
    padding: 20px
}

.pt-1 {
    padding-top: 10px
}

.pt-2 {
    padding-top: 20px
}

.pb-1 {
    padding-bottom: 10px
}

.pb-05 {
    padding-bottom: 5px
}

.py-1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important
}

.px-1 {
    padding-left: 10px !important;
    padding-right: 10px !important
}

.py-05 {
    padding-top: 5px !important;
    padding-bottom: 5px !important
}

.px-2 {
    padding-left: 20px !important;
    padding-right: 20px !important
}